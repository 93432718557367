.category-card {
  width: 182px;
}

.user-card-wrapper {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  padding-left: 15px;
}

.user-story {
  margin: 0 auto;
  max-width: 550px;
  position: relative;
  margin-bottom: 30px;

  .user-story-header {
    display: flex;
    background-color: #f5f5f5;
    padding: 14px;
    justify-content: space-between;
    align-items: center;

    .ant-card-meta {
      display: flex;
      cursor: pointer;

      .ant-card-meta-avatar {
        margin-right: 10px;
      }

      .ant-card-meta-detail {
        display: flex;
        align-items: center;
      }
    }

    .user-story-timeline {
      margin-right: 15px;
      font-size: 16px;
      font-weight: 500;
      color: #898989;
    }
  }

  video {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }

  .user-story-footer {
    background-color: #f5f5f5;
    padding: 14px;
    display: flex;

    .ant-space-item {
      color: #898989;
      font-weight: 600;
    }
  }
}

.dark {
  .user-story-header {
    background-color: #171717;
    color: rgba(255, 255, 255, 0.85);

    .user-story-timeline {
      color: rgba(255, 255, 255, 0.85);
    }
  }

  .user-story-footer {
    background-color: #171717;
    display: flex;
    align-items: center;

    .ant-space-item {
      color: rgba(255, 255, 255, 0.45);
    }
  }
}

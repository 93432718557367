.auth-wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  height: 100%;

  .left-container {
    background-image: linear-gradient(
        to bottom,
        rgba(165, 88, 200, 0.3),
        rgba(165, 88, 200, 0.3)
      ),
      url('../../assets/images/login-background.png');
    background-repeat: no-repeat;
    background-position: bottom left;

    .logo {
      display: block;
      margin: 6.8% auto 0;
    }
  }

  .right-container {
    padding: 14% 24% 0 26%;

    .logo {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .auth-wrapper {
    .left-container {
      display: none;
    }

    .right-container {
      padding: 20px;

      button {
        width: 100%;
      }
      .logo {
        display: block;
        margin: 0 auto;
      }
    }
  }
}

.left-panel-wrapper {
  padding-left: 18px;
  padding-top: 25px;
  height: 100%;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .theming {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin-bottom: 45px;
  }

  .navigation {
    .logo {
      background-image: url('./../../assets/images/logo-light.svg');
      width: 90%;
      height: 70px;
      max-width: 254px;
      margin-bottom: 50px;
      background-repeat: no-repeat;
    }

    .ant-menu-root {
      padding-right: 46px;
      border: none;
    }
    .ant-menu-item {
      border-radius: 0;
    }

    .ant-menu-item-selected {
      border-right: 3px solid #7b61ff;
    }

    .anticon-heart svg {
      fill: #f759ab;
    }
    .special-link {
      color: #f759ab;
    }
  }

  .user-snippet {
    display: flex;
    align-items: center;
    cursor: pointer;

    .create-drop {
      color: white;
      background-color: #a558c8;
      padding: 5px 20px;
      font-size: 18px;
    }

    .ant-typography {
      line-height: normal;
      margin: 0;
      font-size: 20px;
      margin-left: 10px;
    }
  }
}

.dark {
  .left-panel-wrapper {
    .ant-layout-sider-children {
    }

    .navigation {
      .logo {
        background-image: url('./../../assets/images/logo-dark.svg');
      }

      .ant-menu-root {
        color: rgba(255, 255, 255, 0.85);
        background-color: #0d0d0d;
      }

      .ant-menu-item {
        &:hover {
          color: rgba(255, 255, 255, 0.85);
          background: #1e0631;
        }
      }

      .ant-menu-item-selected {
        color: #a069e2;
        border-right: 3px solid #a069e2;
        background: #22003d;

        &:hover {
          color: #a069e2;
        }
      }
    }

    .user-snippet {
      .create-drop {
        color: white;
      }

      .ant-typography {
      }
    }
  }
}

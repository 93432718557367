$left-panel-width: 342px;
$right-panel-width: 384px;
$lp-width-mobile: 28%;
$rp-width-mobile: 30%;

.dark {
  .home-wrapper {
    .left-panel-wrapper,
    .header-wrapper,
    .content-wrapper,
    .right-panel-wrapper {
      background-color: #0d0d0d !important;
    }
  }
}

.home-wrapper {
  height: 100%;
  display: flex;

  .left-panel-wrapper,
  .header-wrapper,
  .content-wrapper,
  .right-panel-wrapper {
    background-color: white;
  }

  .content-wrapper {
    margin-top: 64px;
  }
}

.left-panel {
  max-width: $left-panel-width;
  width: $left-panel-width;

  .left-panel-wrapper {
    width: $left-panel-width !important;
    max-width: $left-panel-width !important;
  }
}

.right-panel {
  max-width: $right-panel-width;
  width: $right-panel-width;

  .right-panel-wrapper {
    width: $right-panel-width !important;
    max-width: $right-panel-width !important;
  }
}

.breakpoint-position {
  position: fixed;
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .left-panel {
    max-width: $lp-width-mobile;
    width: $lp-width-mobile;

    .left-panel-wrapper {
      width: $lp-width-mobile !important;
      max-width: $lp-width-mobile !important;
    }
  }

  .right-panel {
    max-width: $rp-width-mobile;
    width: $rp-width-mobile;

    .right-panel-wrapper {
      width: $rp-width-mobile !important;
      max-width: $rp-width-mobile !important;
    }
  }
}

html,
body,
#root,
.App,
.ant-layout,
.middle-section,
.ant-layout-content {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-input-affix-wrapper,
.ant-btn,
.ant-checkbox {
  border-radius: 2px !important;
}

textarea.ant-input {
  font-size: 17px !important;
}

.dark {
  ::-webkit-scrollbar-track {
    background-color: #242424;
  }

  ::-webkit-scrollbar {
    background-color: #272727;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #696969;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  border-radius: 4px;
  width: 7px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bdbdbd;
}

.post-modal-user {
  .ant-modal-content {
    max-height: 90vh;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  .middle-section {
    height: calc(100% - 11px);
  }
}

.suggestion-item-wrapper {
  border-radius: 6px;
  background-size: cover;
  padding-left: 12px;
  height: 90px;
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    overflow: hidden;
  }
}

.profile {
  position: relative;

  .profile-cover-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }

  .profile-info {
    position: absolute;
    margin-top: -130px;
    padding: 0 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .profile-info-user {
      display: flex;
      border-radius: 15px;
      background: rgb(0 0 0 / 77%);
      width: 88%;
      padding: 0 15px;
      align-items: center;
      justify-content: space-between;

      h3 {
        line-height: 10px;
        color: white;
      }
    }
  }

  .profile-bio {
    padding: 30px 10px;
    word-break: break-word;
  }

  .profile-tab-content {
    .ant-tabs-nav {
      padding: 0 30px;
      background: white;
    }

    .ant-tabs-content-holder {
      min-height: 300px;
    }
  }
}
.dark {
  .profile {
    color: white;
  }
}
.toolbar-wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgb(0 0 0 / 95%);
  border-radius: 100px;
  transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}

@media only screen and (max-width: 768px) {
  .profile {
    .profile-cover-image {
      width: 100%;
      height: auto;
      min-height: 300px;
      max-height: 350px;
      object-fit: cover;
    }

    .profile-info {
      .profile-info-user {
        width: 60%;
      }
    }
  }
}

.filter-btn {
  border-radius: 2px;
}

.active {
  border-radius: 2px;
  border: 1px solid #1890ff;
  background: #1890ff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: #fff;

  &:hover {
    border: 1px solid #1890ff !important;
    color: #fff !important;

    span {
      border-color: #1890ff !important;
    }
  }
}

.profile-content {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 20px;
  align-items: stretch;

  .profile-media {
    width: 100%;
    height: 240px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    display: flex;

    img,
    video {
      width: 100%;
      object-fit: cover;
    }

    .profile-media-hover {
      display: none;
      position: absolute;

      background-color: #000000bf;
      width: 100%;
      height: 100%;
      z-index: 1000;
    }

    .count-up {
      color: white;
      font-size: 25px;
      margin-left: 5px;
      margin-right: 30px;
    }

    &:hover {
      .profile-media-hover {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .user-story-header {
    display: flex;
    background-color: #f5f5f5;
    padding: 14px;
    justify-content: space-between;
    align-items: center;

    .ant-card-meta {
      display: flex;

      .ant-card-meta-avatar {
        margin-right: 10px;
      }
    }

    .user-story-timeline {
      margin-right: 15px;
      font-size: 16px;
      font-weight: 500;
      color: #898989;
    }
  }
}

.dark {
  .profile-bio {
    color: white;
    background: #111;
  }
  .profile-content {
    background: #111;

    .profile-media {
      img,
      video {
      }

      .profile-media-hover {
        background-color: #000000bf;
      }

      .count-up {
        color: white;
      }

      &:hover {
        .profile-media-hover {
        }
      }
    }

    .user-story-header {
      background-color: #171717;

      .ant-card-meta {
        .ant-card-meta-avatar {
        }
      }

      .user-story-timeline {
        color: #898989;
      }
    }
  }
}

.post-modal-user {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content !important;
}

@media only screen and (max-width: 768px) {
  .profile-content {
    padding: 20px 0;
  }
}

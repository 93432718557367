.uploadPost {
  width: 100px;
  height: 100px;
  background: #e9e9e9;
  border: 1px dashed #cbcbcb;
  position: relative;

  .labelForUpload {
    width: 100%;
    position: absolute;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: static;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.settings-list {
  .ant-list-item {
    padding: 20px;
    color: black;
    font-size: 15px;
    font-weight: bold;
  }
}

.dark {
  .settings-list {
    .ant-list-item {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

.chat {
  height: calc(100vh - 78px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
  height: 99%;

  .chat-input {
    display: flex;
  }

  #chatContent {
    padding: 0;
    height: 100%;
  }
}

#chatContentVL {
  & > div {
    padding: 0 10px;
  }
}

.ant-list-item-meta {
  padding: 0 20px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .chat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10px;
    height: calc(100% - 53px);
  }
}

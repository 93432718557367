.right-panel-wrapper {
  background-color: white;

  .search-wrapper {
    display: flex;
    padding-top: 20px;
    padding-right: 22px;
    justify-content: flex-end;
    margin-bottom: 0;

    input {
      border-radius: 2px;
    }
  }

  .suggestions-wrapper {
    padding-right: 40px;
    padding-left: 30px;

    .titles-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
    }
  }
}

.dark {
  .right-panel-wrapper {
    background-color: #0d0d0d;

    .search-wrapper {
      display: flex;
      padding-top: 20px;
      padding-right: 22px;
      justify-content: flex-end;
      margin-bottom: 0;

      .ant-input-affix-wrapper {
        background-color: #0d0d0d;
      }

      input {
        border-radius: 2px;
        background-color: #0d0d0d;
      }
    }

    .suggestions-wrapper {
      padding-right: 40px;
      padding-left: 30px;

      .titles-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;
      }
    }
  }
}

.chat-row {
  display: flex;
  padding-block: 15px 0;
  align-items: flex-end;

  .chat-text {
    background-color: #ebebeb;
    margin-left: 5px;
    border-radius: 5px 5px 5px 0;
    padding: 10px;
    word-break: break-word;
  }

  &.chat-row-me {
    flex-direction: row-reverse;

    .chat-text {
      background-color: #a558c8;
      color: white;
      margin-right: 5px;
      border-radius: 5px 5px 0px 5px;
      padding: 10px;
      word-break: break-word;
    }
  }

  .ant-avatar {
    border: 1px solid #a558c8;
    flex-shrink: 0;
  }
}

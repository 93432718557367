.post-modal {
  display: flex;
  align-items: flex-start;

  .post-modal-image {
    display: flex;
    align-items: center;
  }

  .post-modal-comments {
    width: 300px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: space-between;

    .ant-card-meta {
      display: flex;
      align-items: center;

      .ant-card-meta-avatar {
        margin-right: 10px;
      }
    }
  }

  .post-modal-image {
    img,
    video {
      max-height: 84vh;
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .post-modal-user {
    .ant-modal-content {
      max-height: unset;
      margin: 10px 0;

      .ant-modal-body {
        margin-top: 40px;

        .post-modal {
          flex-direction: column;

          .post-modal-image {
            width: 100%;
            margin-bottom: 30px;
          }

          .post-modal-comments {
            width: 100%;

            .ant-card-meta {
              .ant-card-meta-avatar {
                margin-right: 10px;
              }
            }

            .ant-list-split {
              height: 100% !important;
            }
          }
        }
      }
    }
  }
}

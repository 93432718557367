.custom-send {
  margin: 0px;
  position: absolute;
  right: 10px;
  top: 13px;
}

.custom-comment {
  .ant-comment-inner {
    padding-block: 0;
  }
}

.comment-list {
  overflow-y: auto;
}

.header-wrapper {
  background-color: white;
  padding: 16px 0 0 0;
  position: fixed;
  top: 0;
  z-index: 10;
  width: calc(100% - 726px);

  .header-link {
    display: flex;
    padding: 6px 24px;
    align-items: center;
    gap: 16px;
    a {
      color: black;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .ant-tabs-nav {
    margin: 0;
    padding-left: 24px;
    background-color: white;
  }
}

.content-wrapper,
.ant-tabs {
  background-color: #fbfbfb !important;
}

.dark {
  .header-wrapper {
    .header-link {
      a {
        color: rgba(255, 255, 255, 0.85);
      }
    }

    .ant-tabs-nav {
      background-color: #0d0d0d;
    }
  }
  .content-wrapper {
    .ant-tabs-nav {
      background-color: #0d0d0d !important;

      &::before {
        border-color: rgba(255, 255, 255, 0.06);
      }
    }
    .ant-tabs-nav-wrap {
      color: rgba(255, 255, 255, 0.85);
    }
    .ant-tabs {
      background-color: #111 !important;
    }
  }
}

@media only screen and (max-width: 1298px) {
  .header-wrapper {
    width: 42%;
  }
}

@media only screen and (max-width: 996px) {
  .header-wrapper {
    width: 72%;
  }
}

@media only screen and (max-width: 768px) {
  .header-wrapper {
    width: 100%;
  }
  .middle-section {
    margin: 0;
  }
}

.categories-wrapper {
  padding-top: 14px;
  padding-left: 24px;
  background-color: #fbfbfb !important;

  .ant-card {
    border-radius: 14px;
    overflow: hidden;

    .ant-card-body {
      padding: 15px 0;

      .ant-card-meta-avatar {
        padding: 0 12px;
      }

      .ant-card-meta-title,
      .ant-card-meta-description {
        font-size: 14px;
        margin: 0;
      }
    }
  }
}

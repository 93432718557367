.bottom-panel-wrapper {
  position: fixed;
  display: block;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);

  &.safari-only {
    .ant-menu-horizontal {
      .ant-menu-item {
        height: 86px;
        justify-content: start;
        padding: 5% 6%;

        &.ant-menu-item-selected {
          border-bottom: none;
        }
      }
    }
  }

  .ant-menu-horizontal {
    display: flex;
    justify-content: space-between;

    .ant-menu-item {
      align-items: center;
      padding: 0 6%;
      height: 56px;
      width: calc(100% / 6);
      justify-content: center;
      order: unset !important;
      display: flex;
      flex-direction: column;
      border-bottom: 3px solid transparent;

      &::before,
      &::after {
        content: unset;
      }

      &.ant-menu-item-selected {
        border-bottom: 3px solid #7b61ff;
        background-color: #fdf0ff;
      }

      .anticon-heart svg {
        fill: #f759ab;
      }
      .special-link {
        color: #f759ab;
      }
      .ant-menu-title-content {
        font-size: 0;
        width: 0;
        height: 0;
      }

      & > span {
        font-size: 25px;
      }
    }
  }
}

.dark {
  .bottom-panel-wrapper {
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);

    &.safari-only {
      .ant-menu-horizontal {
        .ant-menu-item {
          &.ant-menu-item-selected {
          }
        }
      }
    }

    .ant-menu-horizontal {
      .ant-menu-item {
        &.ant-menu-item-selected {
          border-bottom: 3px solid #7b61ff;
          background: #22003d;
        }

        .anticon-heart svg {
          fill: #f759ab;
        }
        .special-link {
          color: #f759ab;
        }
        .ant-menu-title-content {
        }
      }
    }
  }
}
